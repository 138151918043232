<template>
  <!-- 充提审核 -->
  <div>
    <!-- 顶部选择框 -->
    <div style="padding: 30px">
      <el-radio-group v-model="form.type" @change="storeage">
        <el-radio-button :label="1">提现审核</el-radio-button>
        <el-radio-button :label="2">充值记录审核</el-radio-button>
      </el-radio-group>
    </div>
    <div class="form">
      <!-- 按时间查询 -->
      <el-date-picker
        v-model="value"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd 00:00:00"
        @change="pickerChange"
        style="width: 280px"
      >
      </el-date-picker>
      <!-- <el-input v-model="form.bankName" placeholder="" clearable @clear="Remove" style="width: 200px; margin-left: 10px">
        <template #prepend> 银行名称 </template>
      </el-input> -->
      <el-input v-model="form.cardNumber" placeholder="" clearable @clear="Remove" style="width: 250px; margin-left: 10px">
        <template #prepend> 卡号 </template>
      </el-input>
      <el-input v-model="form.tel" placeholder="" clearable @clear="Remove" style="width: 200px; margin-left: 10px">
        <template #prepend> 电话 </template>
      </el-input>
      <el-input v-model="form.userId" placeholder="" clearable @clear="Remove" style="width: 200px; margin-left: 10px">
        <template #prepend> 用户Uid </template>
      </el-input>
      <!-- <el-input v-model="form.userName" placeholder="" clearable @clear="Remove" style="width: 200px; margin-left: 10px">
        <template #prepend> 用户名 </template>
      </el-input> -->
      <el-select v-model="form.status" placeholder="状态" clearable @clear="Remove" style="width: 120px; margin-left: 10px">
        <el-option v-for="item in statausList" :key="item.id" :label="item.label" :value="item.value"></el-option>
      </el-select>

      <el-button type="primary" icon="el-icon-search" @click="doFilter" style="margin: 0 10px">搜索</el-button>
      <el-button type="primary" icon="el-icon-search" @click="batch" style="margin: 0 5px">批量通过</el-button>
      <el-button type="primary" icon="el-icon-search" @click="exportData" style="margin: 0 5px">生成表格</el-button>
      <download-excel v-if="derivedForm" class="export" :data="derivedForm" :fields="jsonFields" type="xls" name="充值提现审核表.xls">
        下载表格
      </download-excel>
    </div>
    <!-- <div style="margin-top: 20px; margin-bottom: 40px">
      <el-input v-model="huifubao" placeholder="" clearable disabled style="width: 300px; margin-left: 10px">
        <template #prepend>汇付宝</template>
      </el-input>
      <el-input v-model="lianlianfu" placeholder="" clearable disabled style="width: 300px; margin-left: 10px">
        <template #prepend>连连付</template>
      </el-input>

      <el-input v-model="newlian" placeholder="" clearable disabled style="width: 300px; margin-left: 10px">
        <template #prepend>新连连付</template>
      </el-input>
    </div> -->
    <!-- 提现审核 -->
    <div v-if="form.type == 1">
      <!-- 提现审核表格 -->
      <el-table border :data="formData.tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="记录id" prop="id" width="80" align="center"></el-table-column>
        <el-table-column label="UID" prop="userId" width="120" align="center"></el-table-column>
        <el-table-column label="提现时间" prop="createTime" align="center" width="200"></el-table-column>
        <el-table-column label="审核时间" prop="updateTime" align="center" width="160"></el-table-column>
        <el-table-column label="手机号" align="center" prop="tel" width="120"></el-table-column>
        <el-table-column label="第三方回执单号" align="center" prop="orderSn" width="120"></el-table-column>
        <el-table-column label="到账金额" align="center" prop="actualAmount" width="120"></el-table-column>
        <el-table-column prop="serverChange" align="center" label="手续费" width="100"></el-table-column>
        <!-- <el-table-column prop="" label="账户类型" width="120"></el-table-column> -->
        <el-table-column prop="openBank" label="账户类型" align="center" width="120"></el-table-column>
        <el-table-column prop="subBranch" label="支行信息" width="150"></el-table-column>
        <el-table-column prop="cardNumber" label="账户信息" width="200"></el-table-column>
        <el-table-column prop="userName" label="提现人姓名" width="100"></el-table-column>
        <el-table-column label="状态" width="80">
          <template v-slot="scope">
            <div>
              {{ scope.row.status | statusFilter }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="updateBy" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.status">
              {{ scope.row.updateBy }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="failedReason" label="失败原因" width="180"> </el-table-column>
        <!-- <el-table-column prop="sourceAsMap.withdrawReason" label="三方提现失败原因"> </el-table-column>
        <el-table-column prop="sourceAsMap.transferReason" label="三方转出失败原因"> </el-table-column> -->
        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <el-button type="success" size="mini" @click="handleEdit(scope.row, 1)">通过</el-button>
              <el-button type="danger" size="mini" @click="handleEdit(scope.row, 0)">不通过</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <el-table border :data="formData.tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="记录id" prop="id" width="80" align="center"></el-table-column>
        <el-table-column label="UID" prop="userId" width="120" align="center"></el-table-column>
        <el-table-column label="提交时间" prop="createTime" align="center" width="200"></el-table-column>
        <el-table-column label="审核时间" prop="updateTime" align="center" width="160"></el-table-column>
        <el-table-column align="center" prop="amount" label="充值金额" width="120"></el-table-column>
        <el-table-column label="手机号" align="center" prop="tel" width="120"></el-table-column>
        <el-table-column label="到账金额" align="center" prop="actualAmount" width="120"></el-table-column>
        <el-table-column prop="serverChange" align="center" label="手续费" width="100"></el-table-column>
        <!-- <el-table-column prop="" label="账户类型" width="120"></el-table-column> -->
        <el-table-column prop="openBank" label="账户类型" align="center" width="120"></el-table-column>
        <el-table-column prop="subBranch" label="支行信息" width="150"></el-table-column>
        <el-table-column prop="cardNumber" label="账户信息" width="200"></el-table-column>
        <el-table-column label="订单编号" align="center" prop="orderSn" width="180"></el-table-column>
        <el-table-column show-overflow-tooltip label="第三方支付单号" align="center" prop="paySn" width="180"></el-table-column>
        <el-table-column label="支付方式" align="center" width="100">
          <!-- 文付方式，9.汇付快捷支付 10.汇付文付宝支付 11.sand一键快捷 12.sand云账户 13.sand银联支付" -->
          <template slot-scope="{row}">
            <el-tag type="">{{
              row.payType == '1' ? '支付宝' : 
              row.payType == '2' ? '微信' :
              row.payType == '3' ? '银联' :
              row.payType == '9' ? '汇付快捷支付':
              row.payType == '10' ? '汇付文付宝支付':
              row.payType == '11' ? '一键快捷':
              row.payType == '12' ? '云账户':
              row.payType == '13' ? '银联支付':'线下'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="充值人姓名" width="100"></el-table-column>
        <el-table-column label="状态" width="80">
          <template v-slot="scope">
            <div>
              {{ scope.row.status | statusFilter }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="updateBy" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.status">
              {{ scope.row.updateBy }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="failedReason" label="失败原因" width="180"> </el-table-column>
      </el-table>
    </div>
    <!-- 弹出框 -->
    <el-popover placement="center" trigger="click" title="银行信息"> </el-popover>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange" background layout="total, prev, pager, next, jumper" :total="formData.total">
      </el-pagination>
    </div>
    <!-- 支付方式（汇付宝/连连付） -->
    <el-dialog title="汇款方式" :visible.sync="showselect" width="30%" center :before-close="closeSelect">
      <span>
        <div class="selectbox">
          <div class="contenbox">
            <!-- <el-radio v-model="withdrawType" label="1" border>汇付</el-radio> -->
            <el-radio v-model="withdrawType" label="2" border :disabled='iSyun!=1'>云账户</el-radio>
            <!-- <el-radio v-model="withdrawType" label="3" border>新连连付</el-radio> -->
          </div>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSelect()">取 消</el-button>
        <el-button type="primary" @click="isTrueEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { xmlUrl } from '../../utils/xmlurl'
export default {
  filters: {
    statusFilter(value) {
      switch (value) {
        case 0:
          return '待审核'
        case 1:
          return '审核成功/转款中'
        case 2:
          return '审核失败'
        case 3:
          return '转出成功'
        case 4:
          return '转出失败'
        case 5:
          return '转出异常'
      }
    }
  },
  data() {
    return {
      tabls: '',
      value: '',
      showselect: false,
      seleceData: '',
      withdrawType: '2',
      form: {
        userId: '',
        bankName: null, // 银行名称
        cardNumber: null, // 卡号
        max: null, // 最大
        min: null, // 最小
        status: null, //0待审核,1成功，2失败
        tel: '', // 电话
        type: 1, //1提现，2充值
        userName: null, // 用户名称
        startTime: '',
        endTime: '',
        currentPage: 1, // 页码
        pageSize: 10 // 每页条数
      },
      formData: {
        total: 0,
        tableData: []
      },
      huifubao: '',
      lianlianfu: '',
      newlian: '',
      derivedForm: '', //导出的表格数据
      // 导出表单的格式
      jsonFields: {
        用户ID: 'id',
        电话: 'tel',
        操作类型: 'type',
        提交时间: 'createTime',
        审核时间: 'updateTime',
        充值提现金额: 'amount',
        开户行: 'openBank',
        银行卡号: 'cardNumber',
        支行名称: 'subBranch',
        持卡人姓名: 'cardholder',
        状态: 'status',
        失败原因: 'failedReason'
      },
      statausList: [
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '审核成功/转款中'
        },
        {
          value: 2,
          label: '审核失败'
        },
        {
          value: 3,
          label: '转出成功'
        },
        {
          value: 4,
          label: '转出失败'
        },
        {
          value: 5,
          label: '转出异常'
        }
      ],
      multipleSelection: [],
      iSyun:'',
    }
  },
  mounted() {
    this.getData()
    // 渠道余额
    // this.channelBbalance()
    // 获取所有导出的数据
    // this.exportData();
  },

  methods: {
    // 点击table 获取不同的数据
    storeage(e) {
      console.log(e)
      this.form.type = e
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.formData.total = 0

      this.getData()
    },
    /**
     * 选择多个item
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    /**
     * 批量处理
     */
    batch() {
      this.$confirm('此操作将通过所选择提现, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let arr = this.multipleSelection.map(item => {
            return item.id
          })
          this.axios
            .post('/admin/userWithdraw/updateBatch', {
              ids: arr,
              status: 1
            })
            .then(res => {
              this.$message.success(res.data.mesg)
              this.getData()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 获取所有需要导出的数据
    exportData() {
      this.$message.success('生成中，请稍候');
      this.axios
        .get('/admin/userWithdraw/withdrawExcel', this.form)
        .then(res => {
          console.log(res)
          if (res.data.code == 200) {
            setTimeout(function(){
              window.open(xmlUrl + res.data.data)
            }, 5000);
          } else {
            console('2222')
          }
        })
        .catch(err => {
          console.log(err)
        })
      // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
      // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
      // this.axios.get('/admin/userWater/getTime', {}).then(res => {
      //   if (res.data.code == -1) {
      //     this.axios
      //       .get('/admin/userWithdraw/withdrawExcel', this.form)
      //       .then(res => {
      //         console.log(res)
      //         if (res.data.code == 200) {
      //           window.open(`http://47.109.88.30:9071/${res.data.data}`)
      //         } else {
      //           console('2222')
      //         }
      //       })
      //       .catch(err => {
      //         console.log(err)
      //       })
      //   } else {
      //     this.$message.error('本时段无法生成表格')
      //   }
      // })
    },
    // 获取数据
    getData() {
      this.axios.get('/admin/userWithdraw/findAll', this.form).then(res => {
        console.log(res, 22222222222222)
        this.formData.total = res.data.data.total
        this.formData.tableData = res.data.data.records
      })
    },
    //渠道余额
    channelBbalance() {
      console.log('这是测试')
      this.axios.get('/admin/userWithdraw/getMerchantAmount', {}).then(res => {
        console.log('test', res)
        this.huifubao = res.data.data.huifubao
        this.lianlianfu = res.data.data.lianlianfu
        this.newlian = res.data.data.lianNew
      })
    },
    // 通过
    handleEdit(row, store) {
      if (store) {
        this.seleceData = {
          id:row.id,
          status: 1
        }
        this.axios.get('/admin/userWithdraw/findYunAccount', {
            userId:row.userId
          }).then(res=>{
            this.iSyun=res.data.data
            this.showselect = true
          })
        
        
        return
        // 通过
        console.log('这是通过')
        
        this.axios
          .get('/admin/userWithdraw/updateStatus', {
            id,
            status: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.mesg)
              this.getData()
            } else {
              this.$message.error(res.data.mesg)
            }
          })
      } else {
        // 弹窗  拒绝原因
        this.$prompt('请输入拒绝原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入拒绝的原因'
        })
          .then(({ value }) => {
            this.axios
              .get('/admin/userWithdraw/updateStatus', {
                failedReason: value,
                id:row.id,
                status: 2
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$message.success(res.data.mesg)
                  this.getData()
                } else {
                  this.$message.error(res.data.mesg)
                }
              })
          })
          .catch(err => {})
      }
    },
    // 通过审核
    isTrueEdit() {
      this.seleceData['withdrawType'] = this.withdrawType
      this.axios.get('/admin/userWithdraw/updateStatus', this.seleceData).then(res => {
        if (res.data.code == 200) {
          this.$message.success(res.data.mesg)
          this.seleceData = ''
          this.showselect = false
          this.withdrawType = ''
          this.getData()
        } else {
          if (res.data.code == -1) {
            this.$message.error(res.data.data)
          } else {
            this.$message.error(res.data.mesg)
          }
        }
      })
      console.log(this.seleceData)
    },
    // 取消选择汇款方式
    closeSelect() {
      this.seleceData = ''
      this.showselect = false
      this.withdrawType = ''
    },
    //   搜索
    doFilter() {
      this.form.currentPage = 1
      this.getData()
    },

    Remove() {
      this.form.currentPage = 1
      this.getData()
    },

    handleCurrentChange(val) {
      this.form.currentPage = val
      this.getData()
    },

    // 时间
    pickerChange(e) {
      if (e) {
        this.form.startTime = e[0]
        this.form.endTime = e[1]
      } else {
        delete this.form.startTime
        delete this.form.endTime
      }
      this.form.currentPage = 1
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  margin-bottom: 30px;
}
.pagination {
  padding: 60px 0 0 100px;
}
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
}
.export:hover {
  cursor: pointer;
}
.selectbox {
  width: 100%;
  // height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  // .contenbox {
  //   border: 1px solid red;
  // }
}
</style>
